<template>
    <div class="main">
        <agent-info-comp></agent-info-comp>

        <agent-sub-title>머니이동</agent-sub-title>


            <div class="gameinfo">
                <div style="width: 100%;text-align: center;margin: 10px">
                    <h5 style="text-align: center">Money</h5>
                    <span style="color: #3a8ee6">{{$store.state.agent.agentInfo.cash|comma}}</span>
                </div>

                <div style="width: 100%;text-align: center">
                    <el-radio size="mini" v-model="move.movetype" :label="agentConst.MOVE_CASH_2_USER" border>move to memeber
                    </el-radio>
                    <el-radio size="mini" v-model="move.movetype" :label="agentConst.MOVE_CASH_2_AGENT" border>move to agent
                    </el-radio>
                </div>
                <div style="width: 100%;margin: 10px 0px;text-align: center">
                    <el-radio size="mini" v-model="move.flowType" :label="agentConst.FLOWTYPE_ADD" border><i
                            class="fa fa-plus-square"> Add</i>
                    </el-radio>
                    <el-radio size="mini" v-model="move.flowType" :label="agentConst.FLOWTYPE_MINUS" border><i
                            class="fa fa-minus"> Deduction</i>
                    </el-radio>
                </div>


                <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
                    <div style="width: 34%">
                        <h5 style="text-align: center">Nickname</h5>
                        <el-input size="mini" v-model="move.nickname"></el-input>
                    </div>
                </div>

                <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
                    <div style="width: 34%">
                        <h5 style="text-align: center">Amount</h5>
                        <el-input size="mini" v-model="move.cash"></el-input>
                    </div>
                </div>
                <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
                    <el-button @click="movenow()">save</el-button>
                    <el-button @click="outerVisible = false">cancel</el-button>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">

            </div>
        </div>

</template>

<script>

    import {Loading} from 'element-ui';
    import AgentSubTitle from "../../components/agent/AgentSubTitle";
    import {agentMixin} from "../../common/agent/agentMixin";
    import {doLogin, moveagentmoney} from "../../network/agent/commonRequest";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";

    export default {
        name: "AgentMoveCash",
        components: {
            AgentInfoComp,
            AgentSubTitle,
        },
        mixins: [agentMixin],
        data() {
            return {
                move: {},
                fullscreenLoading: false,
            }
        },
        methods: {
            movenow() {
                this.fullscreenLoading = true;
                moveagentmoney(this.move).then(res => {
                    this.fullscreenLoading = false;
                    if (res.data.success) {
                        this.$message.success("Money transfer has been completed");
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })

            }
        },
        created() {

        }
    }
</script>

<style scoped>
    .table50 {
        border: 2px solid #0c0e0e;
        margin-top: 5px;
        font-weight: 600;
    }

    .data {
        width: 100%;
        height: calc(100vh - 185px);
        overflow-y: hidden;
        align-items: flex-start;
    }

    .gameinfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        background-color: #b3d8ff;
        padding: 10px;

    }

    .g1 {
        margin-top: 5px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #b3d8ff;
        padding: 5px;
        border-radius: 3px;
    }

</style>